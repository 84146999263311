.header {
    background-color: #090A0C;

    width: 100%;
    
    position: fixed;
    left: 0;
    top: 0;
    
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 24px 56px;

    z-index: 1000;

    &-action {
        display: flex;
        align-items: center;
    }

    & .link:not(:last-child) {
        margin-right: 16px;
    }

    @media screen and (max-width: 900px) {
        padding: 20px;

        &-links {display: none;}

        &-action {
            & .button {
                padding: 4px 12px;
                font-size: 12px;
            }
        }
    }
}