.community {
    display: grid;

    grid-template-columns: 33% 33% 33%;
    gap: 54px 28px;

    margin-top: 64px;

    @media screen and (max-width: 1200px) {
        grid-template-columns: 50% 50%;
    }

    @media screen and (max-width: 950px) {
        grid-template-columns: 100%;
    }
}

.communityItem {
    cursor: pointer;
    
    display: flex;
    align-items: center;

    &-icon {
        display: flex;
        
        margin-right: 16px;
    }

    &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;

        color: #FFFFFF;
    }

    &-description {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        color: #4F4F4F;
    }
}