.button {
    cursor: pointer;

    font-family: 'Poppins';
    border: none;

    &:hover {
        background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%)!important;
    }

    &_view {
        &_default {
            color: #17171A;
            background: #FFFFFF;
            font-style: normal;
            font-weight: 500;
        }

        &_gradient {
            background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);

            font-style: normal;
            font-weight: 500;

            color: #17171A;
        }

        &_black {
            font-style: normal;
            font-weight: 500;

            color: #FFFFFF;

            background: #17171A;

            &:hover {
                color: #17171A;

                & svg {
                    path: #17171A;

                    & * {
                        stroke: #17171A;
                    }
                }
            }
        }
    }

    &_bordered {
        border: 1px solid #5F5F5F;
    }

    &_size {
        &_l {
            padding: 12px 24px;
            border-radius: 32px;
            font-size: 18px;
            line-height: 27px;
        }

        &_m {
            padding: 10px 18px 9px 18px;
            border-radius: 30px;
            font-size: 14px;
            line-height: 21px;
        }
    }
}