.banner {
    overflow: hidden;
    position: relative;
    
    height: calc(100vh - 40px);
    padding: 0px 40px;
    width: 100%;

    padding-top: 88px;

    margin-bottom: 180px;

    &-media {
        & img {
            width: 650px;
            height: 650px;
        }
    }

    &-gradient {
        z-index: -1;

        position: absolute;

        bottom: -50px;
        width: 100%;
        height: 350px;

        filter: blur(60px);

        background: linear-gradient(90.63deg, #2137FC 0.73%, #1DE4FF 99.68%);

        &::before {
            content: '';
            position: absolute;
            top: -100px;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 80%;
            height: 200px;
            border-radius: 50%;
            box-shadow: 0 5px 5px rgba(0, 0, 0, 1);
            background-color: #090A0C;
            filter: blur(60px);
            z-index: -1;
        }
    }

    &-container {
        position: relative;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;

        margin: 0 auto;

        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
    }

    &-content {
        max-width: 500px;
        margin-left: 120px;

        & .button {
            margin-right: 16px;
        }
    }

    &-title {
        font-style: normal;
        font-weight: 600;
        font-size: 82px;
        line-height: 110px;

        color: #FFFFFF;

        margin-bottom: 56px;
    }

    @media screen and (max-width: 1400px) {
        padding-right: 20px;
        padding-left: 20px;

        &-content {
            width: 40%;
        }

        &-title {
            font-size: 72px;
            line-height: 92px;
        }


        &-media {
            width: 60%;


            & img {
                width: 100%;
                height: unset;
            }
        }
    }

    @media screen and (max-width: 1150px) {
        &-title {
            font-size: 60px;
            line-height: 80px;
        }
    }

    @media screen and (max-width: 1000px) {
        &-container {
            padding-left: unset;

            display: flex;
            flex-direction: column-reverse;
            justify-content: start;
            align-items: center;
            max-width: unset;
        }

        &-content {
            width: 60%;
        }

        &-title {
            font-size: 82px;
            line-height: 110px;

            margin-bottom: 26px;

            text-align: center;
        }

        &-media {
            margin-bottom: -50px;
        }

        &-actions {
            display: flex;
            flex-direction: column;
            align-items: center;

            & .button {
                margin-right: 0;
                margin-bottom: 24px;
            }
        }
    }

    @media screen and (max-width: 1000px) {
        margin-bottom: 48px;
        height: unset;

        &-container {
            padding-bottom: 32px;
        }

        &-content {
            margin-left: 0px;
        }
        
        &-media {
            display: flex;
            justify-content: center;

            & img {
                width: 358px;
            }
        }

        &-title {
            font-size: 40px;
            line-height: 59px;
        }
    }

    @media screen and (max-width: 500px) {
        &-media {
            margin-top: -90px;
        }

        &-content {
            width: 100%;
            padding: 0px 16px;
        }

        &-actions {
            & .button {
                width: 100%;
            }
        }
    }
}