.roadmap {
    width: 100vw;
    height: 350px;

    position: relative;

    overflow-x: auto;

    margin-top: 60px;

    &::-webkit-scrollbar {
        background-color: #090A0C;
        
    }
    &::-webkit-scrollbar-thumb {
        background-color: #34373c;
        border-radius: 25px;
    }

    &-scroll {
        width: 2500px;
        height: 100%;

        display: flex;
        align-items: center;
    }

    &-line {
        width: 2500px;
        height: 2px;
    
        z-index: -1;

        background-color: #FFFFFF80;
    }

    &-filled {
        position: relative;

        width: 40%;
        height: 100%;

        background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);

        display: flex;
        align-items: center;

        &::after {
            content: "";
            position: absolute;
            right: 0;

            width: 16px;
            height: 16px;

            border-radius: 50%;

            background: radial-gradient(73.51% 73.51% at 82.14% 15.92%, #00D1FF 19.79%, #009FFF 100%);

        }

        &::before {
            content: "";
            position: absolute;
            right: 0;

            width: 16px;
            height: 16px;

            border-radius: 50%;

            background: radial-gradient(73.51% 73.51% at 82.14% 15.92%, #00D1FF 19.79%, #009FFF 100%);

            filter: blur(0px);
            animation: 4s ease-in-out infinite;
            animation-name: chartDotBackground;
        }
    }

    @media screen and (max-width: 600px) {
        margin-top: 0;
    }
}


.roadmapItem {
    position: absolute;

    width: 230px;

    display: flex;
    flex-direction: column;
    align-items: center;

    background: #090A0C;

    border: 1px solid #4F4F4F;

    border-radius: 8px;

    font-style: normal;
    font-weight: 500;

    color: #FFFFFF;

    &_checked {
        border: 1px solid #59FFEB;

        &::before {
            background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);
        }

        &::after {
            background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);
        }
    }

    &_position {
        &_bottom {
            top: 208px;

            &::before {
                top: -39px;
            }

            &::after {
                top: calc(-39px - 14px);
            }
        }

        &_top {
            bottom: 208px;

            &::before {
                bottom: -39px;
            }

            &::after {
                bottom: calc(-39px - 14px);
            }
        }
    }

    &::before {
        content: "";

        position: absolute;

        height: 39px;
        width: 1px;

        background-color: #4F4F4F;
    }

    &::after {
        content: "";

        position: absolute;

        width: 24px;
        height: 24px;
        border-radius: 50%;

        background-color: #6B6C6D;
    }

    &-title {
        padding: 16px 0px;

        display: flex;
        align-items: center;

        font-size: 16px;
        line-height: 24px;

        & svg {
            margin-right: 4px;
        }
    }

    &-subtitle {
        width: 100%;
        border-top: 1px solid #4F4F4F;

        padding: 10px 0;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 14px;
        line-height: 21px;

        color: #FFFFFF;
    }
}

@keyframes chartDotBackground {
    0% {
        filter: blur(0px)
    }

    50% {
        filter: blur(10px)
    }

    100% {
        filter: blur(0px)
    }
}