.logo {
    @media screen and (max-width: 850px) {
        & path:not(:last-child) {
            display: none;
        }
    }

    @media screen and (max-width: 375px) {
        & path:last-child {
            display: block;
        }
    }
}