.footer {
    height: 100px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    max-width: 1120px;
    margin: 0 auto;

    border-top: 1px solid rgba(255, 255, 255, 0.5);

    &-left {
        display: flex;

        color: #FFFFFF;

        &>div {
            cursor: pointer;
            display: flex;
            align-items: center;

            & svg {
                margin-right: 6px;
            }

            &:not(:last-child) {
                margin-right: 40px;
            }
        }
    }

    &-right {
        display: flex;

        color: #4F4F4F;

        &>div {
            cursor: pointer;
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-right: 40px;
            }
        }
    }

    @media screen and (max-width: 1120px) {
        padding: 0px 20px;
    }

    @media screen and (max-width: 750px) {
        display: flex;
        flex-direction: column;
        align-items: start;

        padding-left: 20px;
        padding-right: 20px;

        padding-top: 32px;
        padding-bottom: 20px;

        height: unset;
        
        &-left {
            width: 100%;

            margin-bottom: 20px;

            & > div {
                
                width: 33%;

                &:not(:last-child) {
                    margin-right: unset;
                }
            }
        }

        &-right {
            width: 100%;
            
            & > div {
                width: 33%;

                &:not(:last-child) {
                    margin-right: unset;
                }
            }
        }
    }
}