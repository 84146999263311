
.about {
    display: grid;
    gap: 24px;

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);

    @media screen and (max-width: 750px) {
        gap: unset;
        row-gap: 24px;

        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
    }
}

.aboutItem {
    display: flex;
    flex-direction: column;
    align-items: center;

    background: #090A0C;
    border-radius: 24px;
    border: 1px solid #5F5F5F;

    padding: 32px 32px 0px 32px;

    overflow: hidden;

    &-head {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &_orient_row {
        flex-direction: row;

        & .aboutItem {
            &-head {
                display: block;
            }

            &-description {
                text-align: left;
            }
        }
    }

    &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 60px;

        color: #FFFFFF;

        margin-bottom: 12px;
    }

    &-description {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;

        color: #CACACC;

        & a {
            text-decoration: underline;
            color: #CACACC;
        }
    }

    &-image {
        position: relative;

        height: 350px;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
            z-index: 1;
        }

        &-shadow {
            position: absolute;

            width: 175px;
            height: 175px;

            z-index: 0;

            background: rgba(22, 171, 255, 0.74);
            filter: blur(60px);
        }
    }

    &:nth-child(1) {
        grid-column: 1 / span 1;
    }

    &:nth-child(2) {
        grid-column: 2 / span 1;
    }

    &:nth-child(3) {
        height: 400px;
        padding: 0px 80px;
        
        grid-column: 1 / span 2;
    }

    @media screen and (max-width: 750px) {
        &-title {
            font-size: 24px;
            line-height: 36px;

            margin-bottom: 12px;
        }

        &-description {
            font-size: 14px;
            line-height: 21px;
        }

        &-image {
            height: 220px;

            & svg {
                height: 150px;
            }

            &-shadow {
                width: 100px;
                height: 100px;
            }
        }

        &:nth-child(1) {
            grid-column: 1;
        }

        &:nth-child(2) {
            grid-column: 1 / span 1;
        }

        &:nth-child(3) {
            height: unset;
            padding: 24px 20px 0px 20px;

            grid-column: 1 / span 2;
        }
    }
}