.link {
    cursor: pointer;
    text-decoration: none;

    font-style: normal;

    color: #FFFFFF;

    padding: 0 10px;

    &_view {
        &_banner {
            font-weight: 500;
        }

        &_header {
            font-weight: 300;
        }
    }

    &_size {
        &_m {
            font-size: 16px;
            line-height: 28px;
        }

        &_l {
            font-size: 18px;
            line-height: 27px;
        }
    }

}