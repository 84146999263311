.networks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.networksItem {
    &-icon {
        display: flex;

        & svg {
            width: 164px;
            height: 164px;
        }
    }

    &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 33px;

        text-align: center;

        color: #FFFFFF;
    }

    @media screen and (max-width: 600px) {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        &-icon {
            & svg {
                width: 96px;
                height: 96px;
            }
        }

        &-title {
            font-size: 16px;
            line-height: 24px;
        }
    }
}