.block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin-bottom: 180px;

    max-width: 100vw;

    &_container {
        max-width: 1120px;
        margin: 0 auto;
        margin-bottom: 180px;
    }

    &:last-child {
        margin-bottom: 96px;
    }

    &-label {
        text-transform: uppercase;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;

        background: linear-gradient(90deg, #59FFEB 0%, #14C7FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        margin-bottom: 8px;
    }

    &-title {
        font-style: normal;
        font-weight: 600;
        font-size: 50px;
        line-height: 75px;

        text-align: center;

        color: #FFFFFF;

        margin-bottom: 16px;
    }

    &-description {
        max-width: 600px;

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;

        color: #CACACC;
        
        margin-bottom: 64px;
    }

    @media screen and (max-width: 1000px) {
        &_container {
            padding: 0px 20px;
        }
    }

    @media screen and (max-width: 600px) {
        margin-bottom: 56px;
        
    }

    @media screen and (max-width: 500px) {

        &-label {
            font-size: 12px;
            line-height: 30px;

            margin-bottom: 0;
        }

        &-title {
            font-size: 28px;
            line-height: 42px;

            margin-bottom: 12px;
        }

        &-description {
            font-size: 16px;
            line-height: 24px;

            margin-bottom: 32px;
        }
    }
}