.info {
    display: flex;
    justify-content: space-between;

    max-width: 1120px;
    margin: 0 auto;

    margin-bottom: 180px;

    @media screen and (max-width: 1150px) {
        padding: 0px 20px;
    }

    @media screen and (max-width: 710px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-bottom: 56px;

        & .infoItem:first-child {
            margin-bottom: 24px;
        }
    }
}

.infoItem {
    padding: 32px;
    max-width: 508px;

    &_view {
        &_white {
            background: #FFFFFF;
            border-radius: 16px;

            color: #17171A;

            & .infoItem {
                &-button {
                    & button {
                        background: #17171A;
                    }
                }
            }
        }

        &_black {
            background: #090A0C;
            border-radius: 8px;
            border: 1px solid #929292;

            color: #FFFFFF;

            & .infoItem {
                &-button {
                    & button {
                        background: #090A0C;
                    }
                }
            }
        }
    }

    &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 60px;

        margin-bottom: 12px;
    }

    &-description {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        margin-bottom: 48px;
    }

    &-button {
        & button {
            display: flex;
            align-items: center;

            padding: 16px 24px;

            border-radius: 40px;

            & svg {
                margin-left: 8px;
            }
        }
    }

    @media screen and (max-width: 1100px) {
        width: 49%;
    }

    @media screen and (max-width: 710px) {
        width: 100%;
    }

    @media screen and (max-width: 500px) {
        border-radius: 24px;

        &-title {
            font-size: 24px;
            line-height: 36px;

            margin-bottom: 12px;
        }

        &-description {
            font-size: 14px;
            line-height: 21px;

            margin-bottom: 32px;
        }

        &-button {
            & button {
                justify-content: center;
                width: 100%;
            }
        }
    }
}