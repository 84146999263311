.partners {
    margin-top: 60px;

    &-row {
        width: 1000px;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    &-item {
        display: flex;
        justify-content: center;

        &_first {
            width: 20%;
            margin-bottom: 50px;
        }

        &_second {}
    }

    @media screen and (max-width: 1000px) {
        &-row {
            width: unset;
            padding: 0px 20px;

            &:first-child {
                margin-bottom: unset;
            }
        }
    }

    @media screen and (max-width: 900px) {
        &-row {
            justify-content: space-evenly;
        }

        &-item {
            &_first {
                width: 50%;
                margin-bottom: 40px;
            }

            &_second {
                width: 50%;
                margin-bottom: 40px;
            }

            &:last-child {
                margin-bottom: unset;
            }
        }
    }
}